<template>
    <div class="base">
        <swiper
            ref="swiperImg"
            class="carousel__cover swiper-container swiper-no-swiping"
        >
            <swiper-slide
                v-for="(achieve, index) in achievements"
                :key="index"
                class="swiper-wrapper"
            >
                <img :src="achieve.cover.src" class="swiper-slide" />
            </swiper-slide>
        </swiper>
        <swiper
            ref="swiper"
            :options="swiperOption"
            @slideChange="handleChange"
            class="carousel swiper-container"
        >
            <swiper-slide
                v-for="(achieve, index) in achievements"
                :key="index"
                class="swiper-wrapper"
            >
                <div @click="goTo(achieve)" class="carousel__item swiper-slide">
                    <div class="carousel__item__title">{{ achieve.title }}</div>
                    <div class="carousel__item__time">
                        {{ achieve.postAt | dotDateFormat }}
                    </div>
                    <div class="carousel__item__excerpt">
                        {{ achieve.excerpt }}
                    </div>
                </div>
            </swiper-slide>
        </swiper>
        <div class="pagination_wrap">
            <div class="btn prev" @click="handlePre"></div>
            <div class="pagination">
                <div
                    class="pagination-index"
                    v-for="(item, i) in achievements"
                    @click="handleClick(i)"
                    :class="{ active: index == i }"
                    :key="i"
                ></div>
            </div>
            <div class="btn next" @click="handleNext"></div>
        </div>
    </div>
</template>

<script>
export default {
    name: "IndexAchievement",
    data() {
        return {
            swiperOption: {
                loop: true,
            },
            achieveIndex: 0,
            achievements: [],
            index: 0,
        };
    },
    created() {
        this.fetchAchievements();
    },
    methods: {
        async fetchAchievements() {
            this.achievements = await this.$http.get("home/achievement");
        },
        handleClick(index) {
            this.$nextTick(() => {
                this.index = index;
                this.$refs.swiper.swiper.realIndex = index;
                this.$refs.swiperImg.swiper.slideToLoop(this.index);
                this.$refs.swiper.swiper.slideToLoop(this.index);
            });
        },
        handlePre() {
            this.index -= 1;
            if (this.index < 0) {
                this.index = this.achievements.length - 1;
            }
            this.$refs.swiperImg.swiper.slideToLoop(this.index);
            this.$refs.swiper.swiper.slideToLoop(this.index);
        },
        handleNext() {
            this.index += 1;
            if (this.index == this.achievements.length) {
                this.index = 0;
            }
            this.$refs.swiperImg.swiper.slideToLoop(this.index);
            this.$refs.swiper.swiper.slideToLoop(this.index);
        },
        handleChange() {
            this.index = this.$refs.swiper.swiper.realIndex;
            this.handleClick(this.index);
        },
        syncIndex() {
            this.index = this.$refs.swiper.swiper.realIndex;
        },
        goTo(item) {
            this.$router.push(`/base/achievement/project/detail/${item._id}`);
        },
    },
};
</script>

<style lang="less" scoped>
.base {
    min-height: 440px;
    position: relative;
}
.carousel {
    z-index: 111;
    width: 381px;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.12);
    margin-left: 0;
    position: relative;
    padding-bottom: 50px;
    overflow: hidden;
}
.carousel__item {
    padding: 40px;
}
.carousel__item__title {
    opacity: 0.9;
    font-size: 22px;
    text-align: left;
    color: #000000;
    letter-spacing: 0;
    line-height: 32px;
}
.carousel__item__time {
    font-size: 16px;
    margin: 20px 0 10px 0;
    text-align: left;
    opacity: 0.3;
    color: #000000;
    letter-spacing: 0;
}
.carousel__item__excerpt {
    font-size: 16px;
    text-align: left;
    opacity: 0.6;
    color: #000000;
    letter-spacing: 0;
    line-height: 28px;
}
.carousel__btn {
    position: absolute;
    bottom: 40px;
}
.carousel__btn:hover {
    cursor: pointer;
}
.carousel__btn__next {
    right: 40px;
}
.carousel__btn__prev {
    left: 40px;
}
.carousel__cover {
    width: 875px;
    height: 440px;
    position: absolute;
    top: 40px;
    right: 0;
    bottom: 0;
}
.carousel__cover img {
    object-fit: cover;
    object-position: center;
}
.pagination_wrap {
    z-index: 9999;
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 30px;
    left: 20px;
    .pagination {
        display: flex;
        align-items: center;
        .pagination-index {
            width: 12px;
            height: 12px;
            display: inline-block;
            border-radius: 100%;
            opacity: 1;
            margin: 0 20px;
            opacity: 0.3;
            background: #00305b;
        }
        .active {
            opacity: 1;
            background: #00305b;
        }
    }
    .btn {
        width: 16px;
        height: 16px;
        margin: 0 20px;
        background-repeat: no-repeat;
        background-position: 0 0;
        background-size: 100% 100%;
    }
    .prev {
        background-image: url("http://cdn.sjtuair.com/77c09370-64e1-11ea-838c-8d8294c3577c.svg");
    }
    .next {
        background-image: url("http://cdn.sjtuair.com/7b125680-64e1-11ea-838c-8d8294c3577c.svg");
    }
    &:hover {
        cursor: pointer;
    }
}
@media screen and (max-width: 960px) {
    .base {
        margin-top: 10px;
        .img_wrap {
            position: relative;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            display: inline-block;
            img {
                width: 42vw;
                height: 42vw;
            }
        }
    }
    .carousel {
        width: 100%;
    }
    .carousel__cover {
        width: 100%;
        position: relative;
        bottom: 0;
        top: 0;
        height: 222px;
    }
    .carousel__item {
        padding: 5vw 8vw;
        .carousel__item__title {
            font-size: 22px;
            margin-bottom: 4vw;
            line-height: 1;
        }
        .carousel__item__content {
            font-size: 18px;
            color: #0091ff;
        }
        .carousel__item__time {
            line-height: 1.5;
            -webkit-line-clamp: 5;
        }
    }
    .pagination_wrap {
        left: 0;
        width: 84vw;
        justify-content: center;
        bottom: 80px;
        top: unset;
        .btn {
            margin: 0 8px;
            width: 12px;
            height: 12px;
        }
        .pagination {
            .pagination-index {
                margin: 0 12px;
                width: 10px;
                height: 10px;
            }
        }
    }
}
</style>
