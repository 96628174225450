<template>
    <div class="carousel-container index-container">
        <swiper
            class="mySwiper"
            ref="mySwiper"
            v-if="lists.length"
            :options="swiperOption"
            @slideChange="handleChange"
        >
            <swiper-slide v-for="item in lists" :key="item._id">
                <div class="swiper-back">
                    <img :src="item.cover.src" alt />
                    <div class="swiper-content">
                        <div class="tag">{{ item.source | tip_filter }}</div>
                        <div class="title row2-ellipse" @click="goTo(item)">
                            {{ item.title }}
                        </div>
                        <div class="swiper-bottom" @click="goTo(item)">
                            <div class="left-line"></div>
                            <div class="read-more">READ MORE ></div>
                        </div>
                    </div>
                </div>
            </swiper-slide>
        </swiper>
        <div class="my-pagination">
            <div class="btn prev" @click="handlePre"></div>
            <div class="pagination">
                <div
                    class="pagination-index"
                    v-for="(item, i) in lists"
                    @click="handleClick(i)"
                    :class="{ active: index == i }"
                    :key="i"
                ></div>
            </div>
            <div class="btn next" @click="handleNext"></div>
        </div>
    </div>
</template>

<script>
import { HttpUtil } from "../../assets/util/http";
import { menu } from "../../assets/util/menu";
import { languageTranslation } from "../../assets/util/language";
const TipMap = {
    NewsModel: "新闻",
    ETalkModel: "eTalk",
    CompetitionModel: "活动竞赛",
    ResearchModel: "科创课题",
    CourseModel: "特色课程",
    TedxModel: "TEDxSJTU",
};
export default {
    data() {
        return {
            lists: [],
            index: 0,
            swiperOption: {
                observer: true, //修改swiper自己或子元素时，自动初始化swiper
                observeParents: true, //修改swiper的父元素时，自动初始化swiper
                loop: true,
                autoplay: {
                    delay: 6000,
                },
            },
        };
    },
    filters: {
        tip_filter(val) {
            return languageTranslation(TipMap[val]);
        },
    },
    created() {
        this.fetchData();
    },
    methods: {
        handleChange() {
            this.index = this.$refs.mySwiper.swiper.realIndex;
        },
        handleClick(index) {
            //   console.log(index);
            //   console.log(mySwiper);
            this.$nextTick(() => {
                this.index = index;
                this.$refs.mySwiper.swiper.realIndex = index;
                this.$refs.mySwiper.swiper.slideToLoop(index, 1000, false);
            });
        },
        handlePre() {
            this.index -= 1;
            //   if(this.index==lists.)
            if (this.index < 0) {
                this.index = this.lists.length - 1;
            }
            this.$refs.mySwiper.swiper.slidePrev();
        },
        handleNext() {
            this.index += 1;
            if (this.index == this.lists.length) {
                this.index = 0;
            }
            this.$refs.mySwiper.swiper.slideNext();
        },
        goTo(item) {
            let source = item.source;
            let urlMap = {
                NewsModel: "news/detail",
                ETalkModel: "eTalk/detail",
                CompetitionModel: "information/competition-activities/detail",
                ResearchModel: "info/project/detail",
                CourseModel: "courses/detail",
                TedxModel: "ted-x",
            };
            let url = urlMap[source];
            if (url !== "ted-x") {
                url += `/${item.infoId}`;
            }
            this.$router.push(`base/${url}`);
        },
        async fetchData() {
            let cond = {};
            cond.page = this.$store.state.page;
            cond.lang = this.$store.state.language;
            let res = await HttpUtil.get("/home/carousel");
            this.lists = res.filter((it) => it.cover?.src);
        },
    },
};
</script>
<style lang="less" scope>
.my-pagination {
    z-index: 999;
    display: flex;
    align-items: center;
    position: absolute;
    left: 50%;
    bottom: 42px;
    transform: translateX(-50%);
    .pagination {
        display: flex;
        align-items: center;
        .pagination-index {
            width: 16px;
            height: 16px;
            background: #2c8dfc;
            display: inline-block;
            border-radius: 100%;
            opacity: 1;
            margin: 0 20px;
        }
        .active {
            background: #fff;
        }
    }
    .btn {
        // background-color: #fff;
        width: 20px;
        height: 20px;
        margin: 0 20px;
        background-repeat: no-repeat;
        background-position: 0 0;
    }
    .prev {
        background-image: url("http://cdn.sjtuair.com/d9e76180-39cd-11ea-8a33-f7ff1525b443.svg");
    }
    .next {
        background-image: url("http://cdn.sjtuair.com/db888e60-39cd-11ea-8a33-f7ff1525b443.svg");
    }
    &:hover {
        cursor: pointer;
    }
}

.carousel-container {
    position: relative;
    height: 760px;
    max-height: 1200px;
    .swiper-container {
        height: 100%;
        max-height: 1200px;
        .swiper-back {
            position: relative;
            width: 100%;
            height: 100%;
            max-height: 1200px;
            img {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
            }
            .swiper-content {
                position: absolute;
                left: 0;
                bottom: 0;
                padding: 12vh 3.33vw;
                width: 100%;
                color: #fff;
                background: linear-gradient(
                    180deg,
                    rgba(0, 0, 0, 0) 0%,
                    rgba(0, 0, 0, 0.82) 100%
                );

                .tag {
                    font-size: 2.16vw;
                    line-height: 3.08vw;
                    font-weight: 500;
                    line-height: 2;
                    text-align: left;
                }
                .title {
                    cursor: pointer;
                    font-size: 2.67vw;
                    font-weight: 500;
                    line-height: 2;
                    text-align: left;
                }
                .swiper-bottom {
                    margin-top: 2vh;
                    display: flex;
                    align-items: center;
                    .left-line {
                        width: 0.4vw;
                        height: 2vw;
                        background-color: #f55e5a;
                    }
                    .read-more {
                        margin-left: 10px;
                        font-size: 1.83vw;
                        font-weight: 500;
                        line-height: 1.5vw;
                        cursor: pointer;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 960px) {
    .my-pagination {
        width: 100%;
        .pagination {
            .pagination-index {
                width: 12px;
                height: 12px;
            }
        }
    }
    .carousel-container {
        height: 300px;
        .swiper-container {
            height: 100%;
            .swiper-back {
                height: 100%;
                .swiper-content {
                    bottom: 0px;
                    .tag {
                        font-size: 20px;
                    }
                    .title {
                        font-size: 16px;
                    }
                    .swiper-bottom {
                        .read-more {
                            font-size: 14px;
                        }
                    }
                }
            }
        }
    }
}
</style>
