<template>
    <div class="carousel_container index-container">
        <swiper
            class="mySwiper"
            ref="mySwiper"
            v-if="lists.length"
            :options="swiperOption"
            @slideChange="handleChange"
        >
            <swiper-slide v-for="item in lists" :key="item._id">
                <div class="swiper-back">
                    <div class="swiper-content">
                        <div class="tag" @click="goTo(item)">
                            {{ item.title }}
                        </div>
                        <div class="title">
                            {{ item.talkDesc }}
                        </div>
                        <div class="desc">
                            <span class="decs_style"
                                >{{ "开讲时间" | language }}：</span
                            >
                            {{ item.startDate }} {{ item.beginAt }}
                        </div>
                        <div class="desc">
                            <span class="decs_style"
                                >{{ "举办场地" | language }}：</span
                            >
                            {{ item.address }}
                        </div>
                        <div class="btn" @click="goTo(item)">
                            {{ "立即预约" | language }}
                        </div>
                    </div>
                    <div class="swiper-img">
                        <img :src="item.src" alt="actives" />
                    </div>
                </div>
            </swiper-slide>
        </swiper>
        <div class="pagination_wrap">
            <div class="btn prev" @click="handlePre"></div>
            <div class="pagination">
                <div
                    class="pagination-index"
                    v-for="(item, i) in lists"
                    @click="handleClick(i)"
                    :class="{ active: index == i }"
                    :key="i"
                ></div>
            </div>
            <div class="btn next" @click="handleNext"></div>
        </div>
    </div>
</template>

<script>
import { HttpUtil } from "../../assets/util/http";
import { menu } from "../../assets/util/menu";
export default {
    data() {
        return {
            lists: [],
            index: 0,
            swiperOption: {
                loop: false,
                autoplay: false,
                // autoplay: {
                //     delay: 10000
                // }
            },
        };
    },
    created() {
        this.fetchData();
    },
    methods: {
        handleChange() {
            this.index = this.$refs.mySwiper.swiper.realIndex;
        },
        handleClick(index) {
            this.$nextTick(() => {
                this.index = index;
                this.$refs.mySwiper.swiper.slideToLoop(this.index);
            });
        },
        handlePre() {
            this.index -= 1;
            if (this.index < 0) {
                this.index = this.lists.length - 1;
            }
            this.$refs.mySwiper.swiper.slideToLoop(this.index);
        },
        handleNext() {
            this.index += 1;
            if (this.index == this.lists.length) {
                this.index = 0;
            }
            this.$refs.mySwiper.swiper.slideToLoop(this.index);
        },
        goTo(item) {
            this.$router.push(`/base/eTalk/detail/${item._id}`);
        },
        async fetchData() {
            let cond = {};
            cond.page = this.$store.state.page;
            cond.lang = this.$store.state.language;
            let res = await HttpUtil.get("/home/eTalk");
            this.lists = res.eTalks.map((item) => {
                const { cover } = item;
                item.src = cover.src;
                return item;
            });
        },
    },
};
</script>
<style lang="less" scoped>
.pagination_wrap {
    z-index: 999;
    display: flex;
    align-items: center;
    position: absolute;
    left: 20px;
    bottom: 42px;
    .pagination {
        display: flex;
        align-items: center;
        .pagination-index {
            width: 12px;
            height: 12px;
            opacity: 0.3;
            background: #00305b;
            display: inline-block;
            border-radius: 100%;
            margin: 0 20px;
        }
        .active {
            background: #00305b;
            opacity: 1;
        }
    }
    .btn {
        width: 16px;
        height: 16px;
        margin: 0 16px;
        background-repeat: no-repeat;
        background-position: 0 0;
        background-size: cover;
    }
    .prev {
        background-image: url("http://cdn.sjtuair.com/77c09370-64e1-11ea-838c-8d8294c3577c.svg");
    }
    .next {
        background-image: url("http://cdn.sjtuair.com/7b125680-64e1-11ea-838c-8d8294c3577c.svg");
    }
    &:hover {
        cursor: pointer;
    }
}

.carousel_container {
    position: relative;
    height: 414px;
    .swiper-container {
        height: 414px;
        // max-height: 880px;
        .swiper-back {
            position: relative;
            width: 100%;
            height: 414px;
            .swiper-img {
                margin-left: 720px;
                padding: 16px;
                height: 100%;
                & > img {
                    display: inline-block;
                    height: 100%;
                    width: 100%;
                }
            }
            .swiper-content {
                width: 720px;
                position: absolute;
                left: 0;
                top: 0;
                padding: 60px 0 0 40px;
                text-align: left;
                .tag {
                    text-align: left;
                    opacity: 0.9;
                    font-size: 22px;
                    color: #000000;
                    letter-spacing: 0;
                    line-height: 32px;
                    cursor: pointer;
                }
                .title {
                    margin-top: 20px;
                    font-size: 16px;
                    text-align: left;
                    margin-bottom: 20px;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 2;
                    overflow: hidden;
                    opacity: 0.6;
                    color: #000000;
                    letter-spacing: 0;
                    line-height: 29px;
                    white-space: pre;
                }
                .desc {
                    text-align: left;
                    font-size: 16px;
                    line-height: 24px;
                    margin-top: 10px;
                    color: #303133;
                    .decs_style {
                        color: #f55e5a;
                    }
                }
                .btn {
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background: #3b95fc;
                    display: inline-block;
                    padding: 10px 30px;
                    border-radius: 2px;
                    margin-top: 20px;
                    font-size: 18px;
                    color: #ffffff;
                    letter-spacing: 0;
                }
                .swiper-bottom {
                    margin-top: 20px;
                    display: flex;
                    align-items: center;
                    .left-line {
                        // width: 6px;
                        // height: 20px;
                        width: 0.6vw;
                        height: 2vw;
                        background-color: #c8161d;
                    }
                    .read-more {
                        margin-left: 2vw;
                        // margin-left: 20px;
                        // font-size: 22px;
                        font-size: 1.83vw;
                        font-weight: 500;
                        // line-height: 30px;
                        line-height: 2.5vw;
                        cursor: pointer;
                    }
                }
            }
        }
    }
}
@media screen and (max-width: 960px) {
    .carousel_container {
        height: 100%;
        .swiper-container {
            .swiper-back {
                .swiper-content {
                    width: 100%;
                    padding: 8vw;
                    text-align: center;
                    .tag {
                        font-size: 18px;
                        line-height: 1;
                    }
                    .title {
                        line-height: 1.5;
                        margin-top: 10px;
                        margin-bottom: 10px;
                        -webkit-line-clamp: 3;
                    }
                    .btn {
                        margin: 6vw auto 0;
                        font-size: 14px;
                        padding: 2.5vw 10vw;
                    }
                }
            }
        }
    }
    .pagination_wrap {
        left: 0;
        width: 84vw;
        justify-content: center;
        bottom: 30px;
        .btn {
            margin: 0 8px;
            width: 12px;
            height: 12px;
        }
        .pagination {
            .pagination-index {
                margin: 0 12px;
                width: 10px;
                height: 10px;
            }
        }
    }
}
</style>
