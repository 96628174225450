<template>
    <div>
        <index-text-header></index-text-header>
        <div class="carousel">
            <Carousel></Carousel>
        </div>
        <div class="main_container">
            <div class="brief">
                <div class="brief_detail">
                    <div class="center">{{ "中心简介" | language }}</div>
                    <div class="word" @click="goCenter">{{ desc }}</div>
                    <div class="about" @click="goCenter">
                        <div class="design"></div>
                        <div>About ></div>
                    </div>
                </div>
            </div>
            <div style="height: 275px; width: 100%"></div>
            <!-- 新闻 -->
            <div class="news">
                <div class="news_title">{{ "新闻" | language }}</div>
                <div class="news_more" @click="goNews">
                    <div class="design"></div>
                    <div class="more_news">{{ "更多新闻" | language }} ></div>
                </div>
            </div>
            <div class="news-box">
                <div
                    class="news_content"
                    v-for="(item, index) in newsList"
                    :key="index"
                >
                    <div class="line"></div>
                    <div class="content_time">
                        {{ item.writeAt | dotDateFormat }}
                    </div>
                    <div class="content_title" @click="goNewsDetail(item)">
                        {{ item.title }}
                    </div>
                    <div class="content_detail" @click="goNewsDetail(item)">
                        {{ "详情" | language }} >
                    </div>
                </div>
            </div>
            <!-- 讲座 -->
            <div class="lecture_wrap" v-if="eTalk">
                <div class="lecture">
                    <div class="news_title">E-Talk</div>
                    <div class="news_more" @click="goLecture">
                        <div class="design"></div>
                        <div class="more_news">
                            {{ "更多讲座" | language }} >
                        </div>
                    </div>
                </div>
            </div>
            <div class="lecture_details">
                <CarouselETalk> </CarouselETalk>
            </div>
            <index-activity class="activity"></index-activity>
            <!-- 成果展示 -->
            <div class="news cgzs">
                <div class="news_title">{{ "成果展示" | language }}</div>
                <div class="news_more" @click="goAcheievement()">
                    <div class="design"></div>
                    <div class="more_news">{{ "更多成果" | language }} ></div>
                </div>
            </div>
            <index-achievement></index-achievement>
            <!-- 校企合作 -->
            <div class="news sqhz">
                <div class="news_title">{{ "校企合作" | language }}</div>
                <div class="news_more" @click="goCooperation()">
                    <div class="design"></div>
                    <div class="more_news">{{ "更多资讯" | language }} ></div>
                </div>
            </div>
            <div class="cooperate">
                <div class="cooperate_left" v-if="cooperateImg">
                    <img :src="cooperateImg" />
                </div>
                <div
                    class="cooperate_right"
                    @click="goCooperationDetail(item)"
                    v-for="(item, index) in cooperation"
                    :key="index"
                >
                    <template v-if="index == 0">
                        <div class="cooperate_title">{{ item.title }}</div>
                        <div class="cooperate_time">
                            {{ item.writeAt | dotDateFormat }}
                        </div>
                        <div class="cooperate_excerpt">{{ item.excerpt }}</div>
                    </template>
                </div>
            </div>
            <div
                :class="{
                    information: true,
                    isLast: index == cooperateList.length - 1,
                }"
                v-for="(item, index) in cooperateList"
                :key="index"
            >
                <div class="information__time">
                    {{ item.writeAt | dotDateFormat }}
                </div>
                <div
                    @click="goCooperationDetail(item)"
                    class="information__title"
                >
                    {{ item.title }}
                </div>
            </div>
            <!-- 协同创新 -->
            <div class="innovate" v-if="innovation.length">
                <div class="title">{{ "协同创新" | language }}</div>
                <div class="image_wrap">
                    <div
                        @click="goInnovation(index)"
                        class="image click-op"
                        v-for="(item, index) in innovation"
                        :key="index"
                    >
                        <img :src="item.src" />
                        <span>{{ item.text | language }}</span>
                    </div>
                </div>
            </div>
            <!-- 服务大厅 -->
            <div class="service_wrap">
                <div class="service"></div>
                <div class="service_text">{{ "服务大厅" | language }}</div>
            </div>
            <div class="service_img" v-if="servieHall.length">
                <div class="inner_img">
                    <div
                        @click="goServiceHall(index)"
                        class="img_wrap click-op"
                        v-for="(item, index) in servieHall"
                        :key="index"
                    >
                        <img :src="item.src" />
                        <div class="text">
                            <span>{{ item.text | language }}</span>
                        </div>
                    </div>
                </div>
            </div>
            <!-- tedx -->
            <div @click="goTedX" class="tex_d" v-if="tedx">
                <div class="tex_wrap">
                    <div class="tex_title">TEDxSJTU</div>
                    <div class="tex_desc">{{ tedx.desc }}</div>
                </div>
                <img :src="tedx.cover.src" />
            </div>
        </div>
        <index-footer></index-footer>
    </div>
</template>

<script>
import IndexTextHeader from "../compontents/index/IndexTestHeader";
import Carousel from "../compontents/index/Carousel";
import CarouselETalk from "../compontents/index/CarouselItem";
import IndexAchievement from "../compontents/index/IndexAchievement";
import IndexActivity from "../compontents/index/IndexActivity";
import IndexFooter from "../compontents/index/IndexFooter";
export default {
    components: {
        IndexTextHeader,
        Carousel,
        CarouselETalk,
        IndexAchievement,
        IndexActivity,
        IndexFooter,
    },
    data() {
        return {
            desc: [],
            newsList: [],
            cooperation: [], //合作
            cooperateList: [], //合作(不包括index==0)
            cooperateImg: "",
            innovation: [
                { text: "学生创新实验室", src: "" },
                { text: "学生科技创新工作室", src: "" },
            ],
            servieHall: [
                { text: "设备预约", src: "" },
                { text: "场地预约", src: "" },
                { text: "文档下载", src: "" },
                { text: "办事指南", src: "" },
                { text: "互动天地", src: "" },
            ],
            eTalk: [],
            tedx: null,
            activity: [],
            footerList: [],
        };
    },
    mounted() {
        this.fetchDesc();
        this.fetchNews();
        // this.fetchGovNews();
        this.fetchCooperation();
        this.fetchInnovation();
        this.fetchServiceHall();
        this.fetchTedx();
        this.fetchActivity();
        this.fetchFooter();
    },
    methods: {
        // 获取中心简介
        async fetchDesc() {
            let res = await this.$http.get("home/desc");
            this.desc = res;
        },
        // 获取新闻
        async fetchNews() {
            let res = await this.$http.get("home/news");
            this.newsList = res;
        },
        async fetchGovNews() {
            let res = await this.$http.get(
                `/information/partNews?keyword=&pageSize=1&pageNum=0`
            );
            const result = res.result.map((item, index) => {
                const { title, writeAt, _id } = item;
                return { title, writeAt, _id, href: "", isGov: true };
            });
            this.newsList.unshift(result);
        },
        // 获取校企合作
        async fetchCooperation() {
            let res = await this.$http.get("home/cooperation");
            this.cooperation = res.cooperations;
            this.cooperateList = res.cooperations.concat();
            this.cooperateList.shift();
            this.cooperateImg = res.cover.src;
        },
        // 获取协同创新
        async fetchInnovation() {
            let res = await this.$http.get("home/innovation");
            res.forEach((r, i) => (this.innovation[i].src = r.src));
        },
        // 获取服务大厅
        async fetchServiceHall() {
            let res = await this.$http.get("home/servicehall");
            res.forEach((r, i) => (this.servieHall[i].src = r.src));
        },
        // 获取ted-x
        async fetchTedx() {
            let res = await this.$http.get("home/tedx");
            let resETalk = await this.$http.get("/home/eTalk");
            this.eTalk = resETalk.cover;
            this.tedx = res;
        },
        // 获取活动竞赛
        async fetchActivity() {
            let res = await this.$http.get("/home/activity");
            this.activity = res;
        },
        // 获取footer logo墙
        async fetchFooter() {
            let res = await this.$http.get("/home/footer");
            this.footerList = res.logos;
        },
        // goto中心简介
        goCenter() {
            this.$router.push("/introduction");
        },
        goNews() {
            this.$router.push("/base/news");
        },
        goNewsDetail(item) {
            // if (item.href) {
            //     location.href = item.href;
            //     return;
            // }
            // if (item.isGov) {
            //     this.$router.push(`/base/govnews/detail/${item._id}`);
            //     return;
            // }
            // this.$router.push(`/base/news/detail/${item._id}`);
            // const obj = this.$router.resolve(`/base/news/detail/${item._id}`);
            // window.open(obj.href, '_blank');
            if (item.href) {
                window.open(item.href, "_blank");
                return;
            }
            const obj = this.$router.resolve(`/base/news/detail/${item._id}`);
            window.open(obj.href, "_blank");
        },
        goLecture() {
            this.$router.push("/base/eTalk");
        },
        goCooperation() {
            this.$router.push("/base/cooperation/news");
        },
        goCooperationDetail(item) {
            this.$router.push(`/base/cooperation/news/detail/${item._id}`);
        },
        goAcheievement() {
            this.$router.push("/base/achievement/project");
        },
        goInnovation(index) {
            if (index == 0) {
                this.$router.push("/base/innovation/ex-lab");
            } else {
                this.$router.push("/base/innovation/e-club");
            }
        },
        goServiceHall(index) {
            switch (index) {
                case 0:
                    this.$router.push("/base/service/device");
                    break;
                case 1:
                    this.$router.push("/base/service/reservation");
                    break;
                case 2:
                    this.$router.push("/base/service/download");
                    break;
                case 3:
                    this.$router.push("/base/service/guidence");
                    break;
                case 4:
                    this.$router.push("/base/service/publish");
                    break;
            }
        },
        goTedX() {
            this.$router.push("/base/ted-x");
        },
    },
};
</script>

<style lang="less" scoped>
.activity {
    margin-top: 80px;
}

.main_container {
    width: 1200px;
    margin: 0 auto;
    position: relative;
    min-height: 1200px;

    .brief {
        width: 1080px;
        background: #00305b;
        position: absolute;
        top: -30px;
        z-index: 99;
        left: 60px;

        .brief_detail {
            text-align: left;
            padding: 30px 50px;

            .center {
                font-size: 26px;
                color: rgba(255, 255, 255, 1);
                line-height: 34px;
                padding-bottom: 10px;
            }

            .word {
                cursor: pointer;
                font-size: 16px;
                color: #ffffff;
                padding-bottom: 10px;
                opacity: 0.9;
                letter-spacing: 0;
                line-height: 29px;
            }

            .about {
                cursor: pointer;
                font-size: 18px;
                color: rgba(255, 255, 255, 1);
                line-height: 26px;

                .design {
                    height: 22px;
                    width: 4px;
                    background: #f55e5a;
                    float: left;
                    margin-right: 12px;
                }
            }
        }
    }

    .news {
        width: 100%;
        height: 34px;
        font-weight: 600;
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: center;

        .news_title {
            color: #000;
            font-size: 30px;
            line-height: 34px;
        }

        .news_more {
            display: flex;
            cursor: pointer;
            align-items: center;

            .design {
                height: 22px;
                width: 4px;
                background: #f55e5a;
            }

            .more_news {
                margin-left: 9px;
                font-size: 18px;
                color: #000;
                opacity: 0.6;
            }
        }
    }

    .news-box {
        min-height: 120px;
        display: flex;
        flex-wrap: wrap;

        .news_content {
            width: 32%;
            margin-top: 15px;
            margin-right: 2%;

            &:nth-child(3n) {
                margin-right: 0;
            }

            text-align: left;

            .line {
                width: 100%;
                height: 1px;
                margin-bottom: 9px;
                background: rgb(0, 0, 0);
                opacity: 0.3;
            }

            .content_time {
                font-size: 16px;
                font-weight: 500;
                color: rgba(0, 0, 0, 1);
                opacity: 0.3;
                margin-bottom: 20px;
                line-height: 22px;
            }

            .content_title {
                cursor: pointer;
                opacity: 0.6;
                font-size: 18px;
                color: #000000;
                letter-spacing: 0;
                line-height: 29px;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 3;
                overflow: hidden;
            }

            .content_detail {
                cursor: pointer;
                font-size: 16px;
                font-weight: 500;
                color: rgba(44, 141, 252, 1);
                letter-spacing: 0;
                line-height: 24px;
                margin-top: 10px;
                display: flex;
                align-items: center;

                > img {
                    width: 16px;
                    height: 15px;
                }
            }
        }
    }

    .lecture_wrap {
        margin-top: 84px;
        position: relative;

        .lecture {
            width: 1040px;
            height: 40px;
            display: flex;
            justify-content: space-between;
            font-weight: 600;

            .news_title {
                color: #000;
                float: left;
                font-size: 30px;
            }

            .news_more {
                display: flex;
                cursor: pointer;
                align-items: center;

                .design {
                    height: 22px;
                    width: 4px;
                    background: #f55e5a;
                    margin-right: 9px;
                }

                .more_news {
                    // margin-left: 9px;
                    font-size: 18px;
                    color: #000;
                    opacity: 0.6;
                }
            }

            > img {
                position: absolute;
                top: 0;
                right: 0;
                z-index: 111;
                width: 440px;
                height: 440px;
                object-fit: cover;
                object-position: center;
            }
        }
    }

    .lecture_details {
        width: 1051px;
        height: 414px;
        margin-top: 40px;
        background: rgba(255, 255, 255, 1);
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.12);
    }

    .cgzs {
        margin-top: 360px;
        margin-bottom: 15px;
    }

    .sqhz {
        margin-top: 120px;
    }

    .science_wrap {
        width: 1200px;
        height: 460px;
        margin-top: 80px;
        display: flex;
        justify-content: space-between;

        .science_left {
            display: flex;

            > img {
                width: 875px;
            }
        }

        .science_right {
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            > img {
                width: 300px;
                height: 210px;
            }
        }
    }

    .result_wrap {
        width: 100%;
        height: 440px;

        .result {
            float: right;
            height: 100%;

            > img {
                width: 875px;
                height: 100%;
            }
        }
    }

    .cooperate {
        width: 100%;
        height: 284px;
        display: flex;
        margin-top: 40px;

        .cooperate_left {
            margin-right: 40px;

            > img {
                width: 400px;
                height: 284px;
                object-fit: cover;
                object-position: center;
            }
        }

        .cooperate_right {
            cursor: pointer;
            flex: auto;
            text-align: left;
            overflow: hidden;
            display: flex;
            flex-direction: column;
            justify-content: center;

            .cooperate_time {
                margin-top: 14px;
                opacity: 0.3;
                font-size: 16px;
                color: #000000;
                letter-spacing: 0;
            }

            .cooperate_title {
                font-size: 22px;
                opacity: 0.9;
                color: #000000;
                letter-spacing: 0;
                line-height: 32px;
            }

            .cooperate_excerpt {
                font-size: 16px;
                margin-top: 11px;
                opacity: 0.6;
                color: #000000;
                letter-spacing: 0;
                line-height: 29px;
            }
        }
    }

    .information {
        display: flex;
        align-items: center;
        height: 70px;
        font-size: 16px;
        margin: 0 20px;
        padding: 20px 0px 12px 9px;
        border-bottom: 1px solid #d5d7da;

        .information__time {
            color: #2c8dfc;
            padding: 8px 69px 8px 0;
            border-right: 1px solid #d5d7da;
            font-weight: 500;
        }

        .information__title {
            cursor: pointer;
            padding-left: 59px;
            opacity: 0.6;
            font-size: 16px;
            color: #000000;
            letter-spacing: 0;
            line-height: 29px;
        }
    }

    .information.isLast {
        border-bottom: none;
    }

    .innovate {
        width: 100%;

        .title {
            width: 100%;
            margin: 80px 0 40px 0;
            font-size: 26px;
            font-weight: bold;
            color: rgba(48, 49, 51, 1);
        }

        .image_wrap {
            display: flex;
            flex-flow: row nowrap;

            .image {
                width: 50%;
                height: 263px;
                margin-left: 30px;
                position: relative;
                overflow: hidden;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    object-position: center;
                    transition: all 0.3s linear;
                }

                span {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    height: 54px;
                    background-color: #00305b;
                    font-size: 18px;
                    color: #fff;
                    padding: 12px 0;
                }

                &:hover img {
                    transform: scale(1.05);
                }
            }

            .image:first-child {
                margin-left: 0;
            }
        }
    }

    .service_wrap {
        position: relative;
        height: 64px;
        overflow: hidden;
        background: #00305b;
        margin-top: 20px;

        // .service {
        //     width: 0;
        //     height: 0;
        //     border-color: transparent #00305b;
        //     border-width: 174px 0 0 84vw;
        //     border-style: solid;
        //     margin-top: -10px;
        // }
        .service_text {
            position: absolute;
            left: 30px;
            top: 20px;
            line-height: 50px;
            font-size: 30px;
            color: #fff;
        }
    }

    .inner_img {
        width: 100%;
        padding: 0 20px;
        display: flex;
        flex-wrap: nowrap;
        background: #00305b;

        .img_wrap {
            width: 20%;
            margin-top: 20px;
            margin-left: 20px;
            height: 234px;
            position: relative;
            overflow: hidden;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
                transition: all 0.3s linear;
            }

            .text {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                display: flex;
                background-color: rgba(48, 49, 51, 0.38);

                span {
                    margin: auto;
                    font-size: 24px;
                    color: #fff;
                    font-weight: 600px;
                    transition: all 0.3s linear;
                }
            }

            &:hover .wrap-img {
                transform: scale(1.03);
            }

            &:hover .text > span {
                transform: translateY(-5px);
            }
        }

        .img_wrap:first-child {
            margin-left: 0;
        }
    }

    @media screen and (max-width: 600px) {
        .inner_img {
            flex-wrap: wrap !important;
        }

        .img_wrap {
            width: 100% !important;
            flex: none;
            margin-left: 0 !important;
        }
    }

    .tex_d {
        cursor: pointer;
        display: flex;
        flex-flow: row wrap;
        align-items: flex-start;
        width: 100%;
        background: #2c8dfc;
        height: 378px;
        position: relative;

        .tex_wrap {
            text-align: left;
            width: 642px;
            margin: 60px 60px 0 60px;

            .tex_title {
                font-size: 30px;
                color: rgba(255, 255, 255, 1);
            }

            .tex_desc {
                margin-top: 20px;
                opacity: 0.9;
                font-size: 16px;
                color: #ffffff;
                letter-spacing: 0;
                line-height: 29px;
            }
        }

        img {
            object-fit: cover;
            object-position: center;
            position: absolute;
            width: 438px;
            height: 100%;
            right: 0px;
            top: 0px;
        }
    }
}

@media screen and (max-width: 960px) {
    .main_container {
        width: 100%;
        padding: 0 8vw;

        .news {
            margin-top: 100px;

            .news_title {
                font-size: 22px;
            }

            .news_more {
                .design {
                    height: 20px;
                }

                .more_news {
                    font-size: 16px;
                }
            }
        }

        .sqhz {
            margin-top: 0px;
        }

        .news-box {
            .news_content {
                margin: 10px 0;

                .content_time {
                    margin-bottom: 10px;
                    font-size: 14px;
                }

                .content_title {
                    line-height: 1;
                    font-size: 16px;
                }

                .content_detail {
                    font-size: 14px;
                    margin-top: 5px;
                }
            }
        }

        .brief {
            left: 8vw;
            width: 84vw;
            padding: 15px 25px;

            .brief_detail {
                padding: 0;

                .center {
                    line-height: 1;
                    font-size: 22px;
                }

                .word {
                    font-size: 14px;
                    text-align: justify;
                    line-height: 1.5;
                    text-indent: 2em;
                }
            }
        }

        .lecture_wrap {
            margin-top: 30px;

            img {
                display: none;
            }

            .lecture {
                width: 100%;

                .news_title {
                    font-size: 22px;
                }

                .news_more {
                    .design {
                        height: 20px;
                    }

                    .more_news {
                        font-size: 16px;
                    }
                }
            }
        }

        .lecture_details {
            margin-top: 20px;
            width: 100%;
            height: 86vw;
        }

        .activity {
            margin-top: 30px;
        }

        .cgzs {
            margin-top: 30px;
        }

        .cooperate {
            flex-direction: column;
            height: auto;
            margin-top: 10px;

            .cooperate_left {
                margin-right: 0;

                img {
                    width: 100%;
                    height: 222px;
                    object-fit: cover;
                }
            }

            .cooperate_right {
                .cooperate_title {
                    margin-top: 10px;
                    font-size: 20px;
                }

                .cooperate_time {
                    margin-top: 0px;
                }

                .cooperate_excerpt {
                    margin-top: 5px;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 5;
                    overflow: hidden;
                }
            }
        }

        .information {
            justify-content: space-between;

            .information__title {
                padding-left: 0;
                font-size: 14px;
                text-align: center;
                width: 50%;
            }

            .information__time {
                font-size: 14px;
                padding: 0;
                text-align: center;
                width: 50%;
            }
        }

        .innovate {
            .title {
                margin: 30px 0 20px 0;
            }

            .image_wrap {
                .image {
                    margin-left: 6vw;
                    height: 39vw;
                }
            }
        }

        .service_wrap {
            height: 60px;

            .service {
                display: none;
            }

            .service_text {
                color: #00305b;
                left: 0%;
                font-size: 16px;
                top: 30%;
                text-align: center;
                width: 100%;
            }
        }

        .service_img {
            padding-bottom: 20px;
        }

        .inner_img {
            height: auto;
        }

        .tex_d {
            flex-direction: column;
            height: auto;

            img {
                position: relative;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                width: 100%;
                display: none;
            }

            .tex_wrap {
                width: 100%;
                margin: 0;
                padding: 5vw 8vw;
            }
        }
    }
}
</style>
