<template>
    <div class="base" v-if="achievements.length">
        <swiper
            ref="swiper"
            :options="swiperOption"
            class="carousel swiper-container swiper-no-swiping"
        >
            <swiper-slide
                v-for="(achieve, index) in achievements"
                :key="index"
                class="swiper-wrapper"
            >
                <img :src="achieve.cover.src" class="swiper-slide-img" />
            </swiper-slide>
        </swiper>
        <swiper
            :options="swiperOption"
            @slideChange="handleChange"
            ref="swiperImg"
            class="carousel__cover swiper-container"
        >
            <swiper-slide
                v-for="(achieve, index) in achievements"
                :key="index"
                class="swiper-wrapper"
            >
                <div class="carousel__item swiper-slide">
                    <div class="carousel__item__title">
                        {{ "活动竞赛" | language }}
                    </div>
                    <div class="carousel__item__content" @click="goTo(achieve)">
                        {{ achieve.title }}
                    </div>
                    <div class="carousel__item__time">{{ achieve.desc }}</div>
                </div>
            </swiper-slide>
        </swiper>
        <div class="pagination_wrap">
            <div class="btn prev" @click="handlePre"></div>
            <div class="pagination">
                <div
                    class="pagination-index"
                    v-for="(item, i) in achievements"
                    @click="handleClick(i)"
                    :class="{ active: index == i }"
                    :key="i"
                ></div>
            </div>
            <div class="btn next" @click="handleNext"></div>
        </div>
        <!--
            <div class="img_wrap img_wrap-upper">
                <img :src="imgList.researchCover.src" />
                <div class="img__cover" @click="goProject()">
                    <span>{{ "科创课题" | language }}</span>
                </div>
            </div>
            <div class="img_wrap img_wrap-lower">
                <img :src="imgList.courseCover.src" />
                <div class="img__cover" @click="goCourse()">
                    <span>{{ "特色课程" | language }}</span>
                </div>
            </div>
         -->
    </div>
</template>

<script>
export default {
    name: "IndexAchievement",
    data() {
        return {
            swiperOption: {
                loop: true,
            },
            index: 0,
            achievements: [],
            imgList: [],
        };
    },
    created() {
        this.fetchAchievements();
    },
    methods: {
        async fetchAchievements() {
            let res = await this.$http.get("home/activity");
            this.achievements = res.competitions;
            this.imgList = res;
        },
        handleClick(index) {
            this.$nextTick(() => {
                this.index = index;
                this.$refs.swiperImg.swiper.slideToLoop(this.index);
                this.$refs.swiper.swiper.slideToLoop(this.index);
            });
        },
        handlePre() {
            this.index -= 1;
            if (this.index < 0) {
                this.index = this.achievements.length - 1;
            }
            this.$refs.swiperImg.swiper.slideToLoop(this.index);
            this.$refs.swiper.swiper.slideToLoop(this.index);
        },
        handleNext() {
            this.index += 1;
            if (this.index == this.achievements.length) {
                this.index = 0;
            }
            this.$refs.swiperImg.swiper.slideToLoop(this.index);
            this.$refs.swiper.swiper.slideToLoop(this.index);
        },

        handleChange() {
            this.index = this.$refs.swiperImg.swiper.realIndex;
            this.handleClick(this.index);
        },
        goTo(item) {
            this.$router.push(
                `/base/information/competition-activities/detail/${item._id}`
            );
        },
        goProject() {
            this.$router.push("/base/info/project");
        },
        goCourse() {
            this.$router.push("/base/courses");
        },
    },
};
</script>

<style lang="less" scoped>
.base {
    min-height: 460px;
    position: relative;
    .img_wrap {
        position: absolute;
        img {
            width: 300px;
            height: 210px;
            object-fit: cover;
            object-position: center;
        }
        .img__cover {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            display: flex;
            background-color: rgba(48, 49, 51, 0.49);

            span {
                margin: auto;
                font-size: 26px;
                color: #fff;
                transition: all 0.3s linear;
            }
        }
        &:hover {
            cursor: pointer;
        }
        &:hover .img__cover > span {
            transform: translateY(-5px);
        }
    }
    .img_wrap-upper {
        top: 0;
        right: 0;
    }
    .img_wrap-lower {
        top: 250px;
        right: 0;
    }
}
.carousel {
    width: 875px;
    height: 460px;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.12);
    margin-left: 0;
    position: relative;
    .swiper-slide-img {
        object-fit: cover;
        width: 100%;
    }
}
.carousel__item {
    padding: 40px;
}
.carousel__item__title {
    text-align: left;
    font-size: 26px;
    line-height: 34px;
    color: rgba(255, 255, 255, 1);
    margin-bottom: 20px;
}
.carousel__item__content {
    text-align: left;
    font-size: 20px;
    line-height: 24px;
    color: rgba(255, 255, 255, 1);
    cursor: pointer;
}
.carousel__item__time {
    font-size: 16px;
    opacity: 0.9;
    color: #ffffff;
    letter-spacing: 0;
    line-height: 29px;
    text-align: left;
    margin-top: 10px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
}

.carousel__btn {
    position: absolute;
    bottom: 40px;
}
.carousel__btn:hover {
    cursor: pointer;
}
.carousel__btn__next {
    right: 40px;
}
.carousel__btn__prev {
    left: 40px;
}
.carousel__cover {
    background: #00305b;
    // width: 760px;
    width: 1200px;
    height: 296px;
    position: absolute;
    left: 0;
    bottom: -310px;
}
.carousel__cover img {
    object-fit: cover;
    object-position: center;
}
.pagination_wrap {
    z-index: 999;
    display: flex;
    align-items: center;
    position: absolute;
    bottom: -280px;
    left: 25px;
    .pagination {
        display: flex;
        align-items: center;
        .pagination-index {
            width: 12px;
            height: 12px;
            display: inline-block;
            border-radius: 100%;
            opacity: 1;
            margin: 0 20px;
            opacity: 0.3;
            background: #ffffff;
        }
        .active {
            opacity: 1;
            background: #fff;
        }
    }
    .btn {
        width: 20px;
        height: 20px;
        margin: 0 20px;
        background-repeat: no-repeat;
        background-position: 0 0;
        background-size: 100% 100%;
    }
    .prev {
        background-image: url("http://cdn.sjtuair.com/d9e76180-39cd-11ea-8a33-f7ff1525b443.svg");
    }
    .next {
        background-image: url("http://cdn.sjtuair.com/db888e60-39cd-11ea-8a33-f7ff1525b443.svg");
    }
    &:hover {
        cursor: pointer;
    }
}
@media screen and (max-width: 960px) {
    .base {
        .img_wrap {
            position: relative;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            display: inline-block;
            img {
                width: 42vw;
                height: 42vw;
            }
        }
    }
    .carousel {
        width: 100%;
        height: 222px;
    }
    .carousel__cover {
        width: 100%;
        position: relative;
        bottom: 0;
    }
    .carousel__item {
        padding: 5vw 8vw;
        .carousel__item__title {
            font-size: 22px;
            margin-bottom: 4vw;
            line-height: 1;
        }
        .carousel__item__content {
            font-size: 18px;
            color: #0091ff;
        }
        .carousel__item__time {
            line-height: 1.5;
            -webkit-line-clamp: 5;
        }
    }
    .pagination_wrap {
        left: 0;
        width: 84vw;
        justify-content: center;
        bottom: calc(~"42vw + 30px");
        .btn {
            margin: 0 8px;
            width: 12px;
            height: 12px;
        }
        .pagination {
            .pagination-index {
                margin: 0 12px;
                width: 10px;
                height: 10px;
            }
        }
    }
}
</style>
